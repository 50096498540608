.blogPage{
    background-image: var(--first-gradient);
}

.blogPage__container {
    grid-template-columns: 1fr;
    gap: 40px;

}

.blogPage__date{
    display: block;
    color: var(--tittle-color);
    font-size: var(--tiny-font-size);
    font-weight: 500;
    margin-bottom: 15px;
}

.blogPage__title {
    font-size: var(--h4-font-size);
    line-height: 1.3;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.7s var(--transition);
}

.blogPage__title:hover {
    color: var(--primary-color);
}

.blogPage__description  {
    margin-bottom: 10px;
}

.blogPage__img{
    margin-top: 30px;
    height: 580px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}