.blog{
    background-image: var(--first-gradient);
}

.blog__container {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

}

.blog__date{
    display: block;
    color: var(--tittle-color);
    font-size: var(--tiny-font-size);
    font-weight: 500;
    margin-bottom: 15px;
}

.blog__title {
    font-size: var(--h4-font-size);
    line-height: 1.3;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.7s var(--transition);
}

.blog__title:hover {
    color: var(--primary-color);
}

.blog__description  {
    margin-bottom: 10px;
}

.blog__img{
    margin-top: 30px;
    height: 180px;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
}